import React from "react";
import ServerPagination from "../../../components/ui/ServerPagination";
import { getDateToStringWithoutTime } from "../../../lib/api";
import { Link } from "react-router-dom";

export const AdminTripsList = ({
  trip,
  totalCount,
  currentPage,
  pagination,
}) => {
  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Trip Id</th>
              <th>Created</th>
              <th>Trip Date</th>
              <th>Activity</th>
              <th className="location-th">Location</th>
              <th>Trip Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          {trip.length > 0 ? (
            <tbody>
              {trip.map((trip) => (
                <tr key={`trip-${trip.id}`}>
                  <td>{trip.id}</td>
                  <td>
                    {getDateToStringWithoutTime(new Date(trip.created_at))}
                  </td>
                  <td>
                    {getDateToStringWithoutTime(new Date(trip.start_date))}
                    {trip.end_date &&
                      ` - ${getDateToStringWithoutTime(
                        new Date(trip.end_date)
                      )}`}
                  </td>
                  <td>{trip.activity}</td>
                  <td>{trip.location}</td>
                  <td>
                    {trip.status.charAt(0).toUpperCase() + trip.status.slice(1)}
                  </td>
                  <td>
                    <div className="flex content-justify-center items-center">
                      <Link
                        className="table-btn signup-btn submit-btn"
                        to={`/admin-32423QeCFer/trip-details/${trip.id}`}
                      >
                        View Details
                      </Link>
                      <Link
                        className="table-btn signup-btn submit-btn"
                        to={`/admin-32423QeCFer/user-details/${trip.user.id}`}
                      >
                        User Details
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={7}>No Data Found</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {trip.length > 0 && (
        <ServerPagination
          allData={trip}
          getPage={(page) => pagination(page)}
          totalCount={totalCount}
          setPage={currentPage}
        />
      )}
    </>
  );
};
