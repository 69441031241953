import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import headerLogo from "../../assets/images/header-logo.svg";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch } from "react-redux";
import { adminAuthActions } from "../../store/admin-auth-slice";
import { logoutAdmin } from "../../lib/admin-api";
import avatarSmall from "../../assets/images/avatar-small.png";
import useHttp from "../../hooks/use-http";
import "./header.scss";

export const AdminHeader = () => {
  const navigate = useNavigate();
  const { sendRequest, status, error } = useHttp(logoutAdmin);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const dispatch = useDispatch();

  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const profileOpen = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const logOutHandler = () => {
    setIsLoggingOut(true);
    dispatch(adminAuthActions.logoutAdmin());
    sendRequest();
  };

  useEffect(() => {
    if (isLoggingOut && status === "completed" && error === null) {
      setIsLoggingOut(false);
      navigate("/admin-32423QeCFer/admin-login", { replace: true });
    }
  }, [status, error, dispatch, navigate]);

  const handleError = (e) => {
    e.target.src = avatarSmall;
  };

  return (
      <>
        <header className="header-wrapper main-header-comp">
          <div className="containerfluid containerStyle flex items-center w-100">
            <div className="logo">
              <Link to="/admin-32423QeCFer">
                <img src={headerLogo} alt="logo" />
              </Link>
            </div>
            <div className="navigation-wrap ml-auto" style={{display: 'flex', alignItems: 'center'}}>
              <div className="res-hamburger">
                <div className="hamburger-menu" onClick={toggleMenu}>
                  &#9776;
                </div>
                <ul className={`sidebar-menu ${isMenuOpen ? 'open' : ''}`}>
                  {isMenuOpen && (
                      <>
                        <li>
                          <Link to="/admin-32423QeCFer" onClick={closeMenu}>Dashboard</Link>
                        </li>
                        <li>
                          <Link to="/admin-32423QeCFer/all-users" onClick={closeMenu}>Users</Link>
                        </li>
                        <li>
                          <Link to="/admin-32423QeCFer/all-trips" onClick={closeMenu}>Trips</Link>
                        </li>
                        <li>
                          <Link to="/admin-32423QeCFer/all-features" onClick={closeMenu}>Features</Link>
                        </li>
                        <li>
                          <Link to="/admin-32423QeCFer/subscription-plans" onClick={closeMenu}>Subscription Plans</Link>
                        </li>
                      </>
                  )}
                </ul>
              </div>
              <OutsideClickHandler
                  onOutsideClick={() => {
                    setIsProfileOpen(false);
                  }}
              >
                <figure onClick={profileOpen} className="HeaderPImage">
                  <img
                      className="head-profile"
                      src={avatarSmall}
                      alt="profile"
                      onError={handleError}
                      style={{
                        width: "45px",
                        height: "45px",
                        objectFit: "cover",
                      }}
                  />
                  <i className="fa fa-angle-down"></i>
                  <figcaption
                      className={`dropdown ${isProfileOpen ? "show" : ""}`}
                  >
                    <Link
                        to="/admin-32423QeCFer/update-password"
                        onClick={profileOpen}
                    >
                      Update Password
                    </Link>
                    <Link
                        to="/admin-32423QeCFer/admin-login"
                        onClick={logOutHandler}
                    >
                      Logout
                    </Link>
                  </figcaption>
                </figure>
              </OutsideClickHandler>
            </div>
          </div>
        </header>
      </>
  );
};
