import React, { useState } from "react";
import { Link } from "react-router-dom";
import userAvatar from "../../../assets/images/avatar-small.png";
import AddPagination from "../../../components/ui/AddPagination";
import { getInitials } from "../../../utilities/NameInitials";

export const AdminAttendingUsersList = (props) => {
  const attendingUsers = props.attendingUsers;
  const invitedUsers = props.invitedUsers;
  const [paginatedAttendingUsers, setPaginatedAttendingUsers] =
    useState(attendingUsers);
  const [paginatedInvitedUsers, setPaginatedInvitedUsers] =
    useState(invitedUsers);
  return (
    <>
      <div className="flex flex-wrap w-100 attending-users content-justify-center">
        <section>
          <h1>Attending Users</h1>
          <ul className="list-style-none flex column-direction w-100">
            {attendingUsers.length > 0 ? (
              paginatedAttendingUsers.map((user, index) => {
                return (
                  <li key={`user-${index}`}>
                    <Link
                      to={`/admin-32423QeCFer/user-details/${user.user_id}`}
                      className="flex w-100 items-center"
                    >
                      {user.attending_user_image ? (
                        <img
                          src={user.attending_user_image}
                          alt={user.attending_user_name}
                        />
                      ) : (
                        <div
                          className="initials-avatar"
                          style={{
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#ccc",
                            color: "#fff",
                            fontSize: "16px",
                            marginRight: "12px",
                          }}
                        >
                          {getInitials(user.attending_user_name)}
                        </div>
                      )}
                      <h2>{user.attending_user_name}</h2>
                      <div className="flex ml-auto items-center list-btns">
                        <button className="table-btn signup-btn submit-btn">
                          View Details
                        </button>
                      </div>
                    </Link>
                  </li>
                );
              })
            ) : (
              <li className="no-trip">No attending users.</li>
            )}
          </ul>
          <AddPagination
            allData={attendingUsers}
            setPaginatedData={setPaginatedAttendingUsers}
            pageSize={5}
          />
        </section>
        <section>
          <h1>Invited Users</h1>
          <ul className="list-style-none flex column-direction w-100">
            {invitedUsers.length > 0 ? (
              paginatedInvitedUsers.map((user, index) => {
                return (
                  <li key={`user-${index}`}>
                    {user.user_id ? (
                      <Link
                        to={`/admin-32423QeCFer/user-details/${user.user_id}`}
                        className="flex w-100 items-center"
                      >
                        {user.invited_user_image ? (
                          <img
                            src={user.invited_user_image}
                            alt={user.invited_user_name}
                          />
                        ) : (
                          <div
                            className="initials-avatar"
                            style={{
                              width: "50px",
                              height: "50px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#ccc",
                              color: "#fff",
                              fontSize: "16px",
                              marginRight: "12px",
                            }}
                          >
                            {getInitials(user.invited_user_name)}
                          </div>
                        )}
                        <h2>{user.invited_user_name}</h2>
                        <div className="flex ml-auto items-center list-btns">
                          <button className="table-btn signup-btn submit-btn">
                            View Details
                          </button>
                        </div>
                      </Link>
                    ) : (
                      <div className="flex w-100 items-center account-not-created">
                        <img src={userAvatar} alt="User Image" />
                        <section>
                          <h2>Account Not Created</h2>
                          <a href={`tel:${user.invited_user_phone_number}`}>
                            <i className="fa fa-phone"></i>{" "}
                            {user.invited_user_phone_number}
                          </a>
                        </section>
                      </div>
                    )}
                  </li>
                );
              })
            ) : (
              <li className="no-trip">No invited users.</li>
            )}
          </ul>
          <AddPagination
            allData={invitedUsers}
            setPaginatedData={setPaginatedInvitedUsers}
            pageSize={5}
          />
        </section>
      </div>
    </>
  );
};
