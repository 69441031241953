// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 644px) {
  .about-wrapper {
    height: calc(100vh - 157px) !important;
    margin-bottom: 80px;
    padding: 50px 0;
  }
}
.about-wrapper h1 {
  font-size: 40px;
  margin: 0 0 20px;
}
.about-wrapper p a {
  font-weight: bold;
  color: #fff;
  text-decoration: underline;
}
.about-wrapper ol li {
  margin: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/informational-pages/about.scss"],"names":[],"mappings":"AACE;EADF;IAEI,sCAAA;IACA,mBAAA;IACA,eAAA;EACF;AACF;AAAE;EACE,eAAA;EACA,gBAAA;AAEJ;AACI;EACE,iBAAA;EACA,WAAA;EACA,0BAAA;AACN;AAGI;EACE,cAAA;AADN","sourcesContent":[".about-wrapper {\n  @media (min-width: 644px) {\n    height: calc(100vh - 157px) !important;\n    margin-bottom: 80px;\n    padding: 50px 0;\n  }\n  h1 {\n    font-size: 40px;\n    margin: 0 0 20px;\n  }\n  p {\n    a {\n      font-weight: bold;\n      color: #fff;\n      text-decoration: underline;\n    }\n  }\n  ol {\n    li {\n      margin: 10px 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
