import React, { useState } from "react";
import ServerPagination from "../../../components/ui/ServerPagination";
import { getDateToStringWithoutTime } from "../../../lib/api";
import TransactionLogModal from "../admin-transactions/TransactionLogModel";

export const AdminTransactionList = ({
  data,
  totalCount,
  currentPage,
  pagination,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleOpenTransactionLogModal = (logs) => {
    setIsModalOpen(true);
    setModalData(logs);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>User</th>
              <th>Plan</th>
              <th>Variation</th>
              <th>Amount</th>
              <th>Session</th>
              <th>Status</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          {data.length > 0 ? (
            <tbody>
              {data.map((item) => (
                <tr key={`transaction-${item.id}`}>
                  <td>{item.user.name}</td>
                  <td>{item.subscription_plan.name} Plan</td>
                  <td>
                    {item.subscription_plan_variation.duration_type} Month
                  </td>
                  <td>
                    {item.amount} {item.currency}
                  </td>
                  <td>{item.session_id}</td>
                  <td>
                    {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                  </td>
                  <td>
                    {getDateToStringWithoutTime(new Date(item.created_at))}
                  </td>
                  <td>
                    <div>
                      <button
                        className="table-btn signup-btn submit-btn"
                        onClick={() =>
                          handleOpenTransactionLogModal(item?.transaction_logs)
                        }
                      >
                        Logs
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={8}>No Data Found</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {data.length > 0 && (
        <ServerPagination
          allData={data}
          getPage={(page) => pagination(page)}
          totalCount={totalCount}
          setPage={currentPage}
        />
      )}
      {/* Render Modal */}
      <TransactionLogModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        logs={modalData}
      />
    </>
  );
};
